import type { FirebaseOptions } from '@firebase/app'

export const clientCredentials: FirebaseOptions = {
  apiKey: 'AIzaSyBRLlMMxcUycQezGPRZprezPuarK5_BxQs',
  authDomain: 'id.airheart.com',
  projectId: 'airheart',
  appId: '1:1058462694248:web:f8bd2941b719748becb4dd',
}

export const seamlessAuthClientId =
  '1058462694248-tuvuaqhincqv0vnbfvd5kk4a4g0ca2ne.apps.googleusercontent.com'
