import { browser } from '$app/environment'

export const prod = import.meta.env.PROD
export const isDev = import.meta.env.DEV
export const isSSR = !browser

export const fetchOptions: RequestInit = {
  credentials: 'include',
  mode: 'cors',
  keepalive: true,
  referrerPolicy: 'origin',
}

export type ResetClientFunc = () => void
