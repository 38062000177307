import { goto } from '$app/navigation'
import state from '$stores/auth-state'
import { deleteApp, initializeApp } from 'firebase/app'
import {
  AuthErrorCodes,
  browserLocalPersistence,
  browserPopupRedirectResolver,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  indexedDBLocalPersistence,
  initializeAuth,
  isSignInWithEmailLink,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  setPersistence,
  signInWithCredential,
  signInWithEmailLink,
  signInWithPopup,
  signInWithRedirect,
  type Auth,
  type AuthError,
  type User,
  type UserCredential,
} from 'firebase/auth'
import { once } from 'lodash-es'
import { setMemberRatesCookie } from 'src/utils/member-rates'
import { reportError, track } from 'src/utils/track'
import { clientCredentials } from './config'
import { handleSignInResult } from './result'

const firebaseApp = initializeApp(clientCredentials, {
  automaticDataCollectionEnabled: false,
  name: 'airheart-app',
})

// initializeFirebaseAuth(firebaseApp)

const getAuthWithUser = once(function init() {
  if (typeof window === 'undefined') return null
  // const newAuth = getAuth(firebaseApp)

  const newAuth = initializeAuth(firebaseApp, {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence],
    popupRedirectResolver: null,
    // popupRedirectResolver: browserPopupRedirectResolver,
  })

  // Check if we're loading immediately after sign-in from redirect
  getRedirectResult(newAuth, browserPopupRedirectResolver)
    .then((cred) => {
      if (cred) {
        return handleSignInResult(cred)
      }
    })
    .catch(handleFirebaseError)

  onAuthStateChanged(
    newAuth,
    async (user) => {
      if (user) {
        await handleSignInResult(user)
      } else {
        state.clearCurrentUser()
        state.setInitialized()
        // window.intercomSettings = {}
      }
    },
    (err) => {
      console.error('Firebase.onAuthStateChanged error', err)
      state.clearCurrentUser()
      reportError(err)
    },
  )

  return newAuth
})

const auth: Auth = getAuthWithUser()

async function currentUser() {
  // if (auth == null) return null
  const auth = getAuthWithUser()
  return new Promise<User>((resolve, reject) => {
    const unsub = onAuthStateChanged(
      auth,
      (user) => {
        resolve(user)
        unsub()
      },
      () => {
        reject()
        unsub()
      },
    )
  })
}

export {
  getAuth as getAuthWithUser,
  currentUser,
  auth,
  signInWithGoogle,
  handleGoogleYoloCredential,
  signInOneTimePassword,
  handleOTPSignIn,
  // otpSignInRedirect,
  signOut,
  destroyFirebaseApp,
}

// function initializeFirebaseAuth(app: FirebaseApp) {
//   if (typeof window === 'undefined') return null
//   if (auth != null) return auth

//   app.automaticDataCollectionEnabled = false
//   const newAuth = initializeAuth(app, {
//     persistence: [browserLocalPersistence],
//     popupRedirectResolver: browserPopupRedirectResolver,
//   })

//   if (newAuth.currentUser) {
//     handleSignInResult(newAuth.currentUser).then(() => state?.setInitialized())
//   }

//   let firstLoad = true

//   onAuthStateChanged(
//     newAuth,
//     async (user) => {
//       if (user) {
//         console.log('initializeFirebaseAuth: currentUser', newAuth.currentUser)
//         if (!firstLoad) await handleSignInResult(user)
//       } else {
//         state.clearCurrentUser()
//         window.intercomSettings = {}
//       }
//       state.setInitialized()
//     },
//     (err) => {
//       console.error('Firebase.onAuthStateChanged error', err)
//       state.clearCurrentUser()
//       reportError(err)
//     },
//     () => {
//       console.log('Authentication observer completed')
//     },
//   )
//   if (newAuth.currentUser != null) {
//     handleSignInResult(auth.currentUser)
//   }

//   firstLoad = false
//   return newAuth

//   // // const newAuth = initializeAuth(app, {
//   // //   // persistence: indexedDBLocalPersistence,
//   // //   // persistence: browserLocalPersistence,
//   // //   popupRedirectResolver: browserPopupRedirectResolver,
//   // // })
//   // const newAuth = getAuth(app)
//   // console.log(newAuth.currentUser)

//   // onAuthStateChanged(newAuth, (user) => {
//   //   console.log('onAuthStateChanged', user)

//   //   if (user) {
//   //     handleSignInResult(user)
//   //   } else {
//   //     // Not signed in
//   //     // FIXME: This branch is called when the page is reloaded, but the user should be signed in.

//   //     // state.clearCurrentUser()
//   //     window.intercomSettings = {}
//   //   }
//   // })
//   // return newAuth
// }

async function signInWithGoogle(): Promise<UserCredential | void> {
  state.clearAuthError()
  const google = new GoogleAuthProvider()
  return setPersistence(auth, browserLocalPersistence).then(() => {
    return (
      signInWithPopup(auth, google, browserPopupRedirectResolver)
        // .then(handleSignInResult)
        .catch((err) => {
          switch (err.code) {
            case AuthErrorCodes.POPUP_BLOCKED:
            case AuthErrorCodes.POPUP_CLOSED_BY_USER:
              console.log('Google popup closed or blocked, trying redirect')

              return (
                signInWithRedirect(auth, google, browserPopupRedirectResolver)
                  // .then(handleSignInResult)
                  .catch(handleFirebaseError)
              )
            default:
              return handleFirebaseError(err)
          }
        })
    )
  })
}

type YoloCredential = {
  credential: string
  selected_by: string
  clientId: string
}

function handleFirebaseError(err: AuthError) {
  console.error('Firebase error', err)

  switch (err?.code) {
    case AuthErrorCodes.POPUP_BLOCKED:
      state.setAuthError(
        "We're sorry, but you've blocked our popup. Please allow popups for this site.",
      )
      break
    case AuthErrorCodes.POPUP_CLOSED_BY_USER:
      state.setAuthError(
        "We're sorry, but you've closed the popup before signing-in. Please try again.",
      )
      break
    case AuthErrorCodes.INTERNAL_ERROR:
      state.setAuthError("We're sorry, but there was an internal error. Please try again.")
      break
    case AuthErrorCodes.NETWORK_REQUEST_FAILED:
      state.setAuthError("We're sorry, but there was a network error. Please try again.")
      break

    case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
      state.setAuthError("We're sorry, but the sign-in request has expired, please try again.")
      break

    case AuthErrorCodes.INVALID_EMAIL:
      state.setAuthError("We're sorry, but that email is invalid. Please try again.")
      break

    case AuthErrorCodes.CODE_EXPIRED:
      state.setAuthError(
        'We are sorry, but your code has expired. Please sign in again to get a fresh code.',
      )
      break
    default:
      state.setAuthError('We are sorry, but there was an authentication error, please try again.')
      break
  }
  return null
}

// handleGoogleYoloCredential handles sign in from Google Seamless Auth.
async function handleGoogleYoloCredential(result: YoloCredential): Promise<User> {
  track('GoogleYolo Authentication Returned')
  const credential = GoogleAuthProvider.credential(result.credential)
  return signInWithCredential(auth, credential)
    .then(handleSignInResult)
    .then((user) => {
      track('GoogleYolo Authentication Completed')
      return user
    })
    .catch((err) => {
      track('GoogleYolo Authentication Failed', { error: err })
      return null
    })
}

async function signInOneTimePassword(email: string, redirectUrl: string): Promise<void> {
  state.clearAuthError()
  const returnURL = otpSignupSettings(redirectUrl, email)
  return sendSignInLinkToEmail(auth, email, returnURL)
    .catch(reportError)
    .then(void 0)
}

const handleOTPSignIn = (
  setShowEmailConfirmation: Dispatch<boolean>,
  emailValue?: string,
): Promise<any> => {
  // Confirm the link is a sign-in with email link.
  if (isSignInWithEmailLink(auth, window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    const url = new URL(window.location.href)
    const email = emailValue?.length > 0 ? emailValue : url.searchParams.get('email')
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      setShowEmailConfirmation(true)

      return null
    }
    // The client SDK will parse the code from the link for you.
    const signInOperation = (emailToSignIn: string) => {
      return signInWithEmailLink(auth, emailToSignIn, window.location.href)
        .then((credential) => {
          // TODO: Clear email from URL

          // Clear email from storage.
          // window.localStorage.removeItem('emailForSignIn')
          // You can access the new user via credential.user
          // Additional user info profile not available via:
          // credential.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // credential.additionalUserInfo.isNewUser
          setShowEmailConfirmation(false)
          return handleSignInResult(credential)
        })
        .catch((error: any) => {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          switch (error?.code) {
            case 'auth/expired-action-code':
              setShowEmailConfirmation(true)
              break

            case 'auth/invalid-email':
              // this can happen if a user attempts to log-in with one e-mail on this device
              // but then uses a sign-up link for a different e-mail account.
              // In that case, the e-mail attached to the OTP link does not match
              // what's in the 'emailForSignIn' item
              // this will allow them to confirm with correct e-mail.
              setShowEmailConfirmation(true)
              break

            case 'auth/invalid-action-code':
              console.error(error)

              return signInOneTimePassword(email, window.location.href).then(() =>
                setShowEmailConfirmation(false),
              )
            default:
              reportError(error)
              throw error
          }
        })
    }

    return signInOperation(email)
  }
}

export function cleanURLAndForwardParams() {
  // get rid of apiKey and other params, keeping pinOnLoad

  const url = new URL(window.location.href)
  const params = url.searchParams

  const strip = ['apiKey', 'mode', 'oobCode', 'continueUrl', 'lang']
  for (const param of strip) {
    params.delete(param)
  }

  url.search = params.toString()
  return goto(url.toString())
}

async function signOut() {
  auth?.signOut()
  state?.clearCurrentUser()
  setMemberRatesCookie(false)

  // eslint-disable-next-line no-undef
  // if (typeof analytics != 'undefined') analytics?.reset()

  if (typeof window !== 'undefined') {
    // to support logging out from all windows
    window?.localStorage?.setItem('logout', new Date().valueOf().toString())
  }

  // Reload window to clear urql state
  if (typeof window != 'undefined') {
    location.reload()
  }
}

function destroyFirebaseApp() {
  deleteApp(firebaseApp)
}

const otpSignupSettings = (redirect: string, email: string) => {
  const url = new URL(redirect)
  url.searchParams.set('email', email)

  return {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: url.toString(),
    // This must be true.
    handleCodeInApp: true,
    // For the future
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'example.page.link'
  }
}
