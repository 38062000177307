export class RoutePath {
  constructor(private origin: string, private destination: string) {}
  toString() {
    return `${this.origin}/${this.destination}`
  }

  static parse(path: string): RoutePath {
    const [origin, destination] = path.split('/')
    return new RoutePath(origin, destination)
  }

  get originId() {
    return this.origin
  }

  get destinationId() {
    return this.destination
  }

  path() {
    return this.toString()
  }

  isValid(): boolean {
    return !!this.origin && !!this.destination
  }
}
