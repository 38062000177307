import { auth } from '$lib/authentication/firebase'
import type { CombinedError } from '@urql/core'
import type { GraphQLError } from 'graphql'

export const onError = async (error: CombinedError) => {
  // handle authErrors before graphqlError because they both
  // rely on graphQLErrors to determine whether handle ther error
  if (isAuthenticationError(error) && auth?.currentUser != null) {
    console.log('User token expired, refreshing...')
    await auth?.currentUser?.getIdToken(true)
    return
  }

  if (graphqlError(error)) {
    return
  }

  if (networkError(error)) {
    return
  }

  // other error
  console.error(`[Airheart error]: ${error.message}`)
}

export function isInputError(error: GraphQLError): boolean {
  return error?.path?.every((p) => p === 'input') ?? false
}

export function isAuthenticationError(error: CombinedError): boolean {
  if (error?.graphQLErrors == null) return false

  return error.graphQLErrors.some(
    (err: GraphQLError) =>
      ['FORBIDDEN', 'UNAUTHENTICATED'].includes(err.extensions?.code) ||
      err?.message?.includes('Not authenticated'),
  )
}

export function isNetworkError(error: CombinedError): boolean {
  return error.networkError != null
}

const graphqlError = (error: CombinedError): boolean => {
  if (error.graphQLErrors.every(isInputError)) {
    return false
  }

  error.graphQLErrors.forEach(({ message, locations, path }) => {
    if (Array.isArray(path) && path.find((p) => p === 'input')) {
      console.error(`[GraphQL Input Error]: ${message}`)
      return
    }

    console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    if (
      message === 'Access denied' ||
      message == 'not authenticated' ||
      /ent: user not found/.test(message)
    ) {
      console.error(`[Airheart]: (Not signed in)`)
      // if (isLoggedIn()) signOut().then(() => window.location.reload())
    }
  })

  return error.graphQLErrors.length > 0
}

const networkError = (error: CombinedError): boolean => {
  if (isNetworkError(error)) {
    console.error(`[Network error]: ${error.networkError.message}:`, error.networkError)
    return true
  }
  return false
}

// const authError = (error: CombinedError): boolean => {
//   const unauthenticatedCode = 'UNAUTHENTICATED'
//   const authErr = error.graphQLErrors.some(
//     (e: GraphQLError) =>
//       e['status'] === unauthenticatedCode || e.originalError['status'] === unauthenticatedCode,
//   )

//   if (authErr) {
//     console.error(`[Auth error]: (Not signed in)`, error.message)
//     return true
//   }
//   return false
// }
