import type { RemoveRoutePinMutation } from '$lib/queries/generated/RemoveRoutePin'
import type {
  CacheExchangeOpts,
  DataFields,
  ResolveInfo,
  Variables,
} from '@urql/exchange-graphcache'
import { relayPagination } from '@urql/exchange-graphcache/extras'
import { insertRoutePinHandler, removeRoutePinHandler } from './cache/pinning'
import { insertReservationHandler } from './cache/reservations'
import type { MutationRemoveRoutePinArgs } from './types.d'

// returning null embeds the data directly into the cache without normalization
export const embed = () => null

function dateTimeResolver(parent: DataFields, args: Variables, _, info: ResolveInfo) {
  return parent[info.fieldName] != null ? new Date(parent[info.fieldName] as string) : null
}

export const normalizedCacheConfig: Partial<CacheExchangeOpts> = {
  // schema: schema,
  keys: {
    PinnedRoute: (r) => r.path as string,
    IntercomUserData: (r) => r.user_id as string,

    //embeds
    TestWindow: embed,
    TravelHistory: embed,
    TravelSourceDocument: embed,
    CovidDataPoint: embed,
    Location: embed,
    OpeningHours: embed,
    OpeningHoursPeriod: embed,
    OpeningHoursOpenClose: embed,
    PropertyAddress: embed,
    Amount: embed,
    Totals: embed,
    OccupancyPricing: embed,
    OccupancyPricingMap: embed,
    CancelPenalty: embed,
    Bed: embed,
    CategorizedCharge: embed,
    NightlyRate: embed,
    OnsitePayments: embed,
    PriceCheckResult: embed,
    HotelProperty: (data): string => {
      if (data?.minInclusivePrice) {
        return data.id + String(data.minInclusivePrice)
      } else {
        return String(data.id)
      }
    },
  },
  optimistic: {
    insertRoutePin: (variables, cache, info) => ({
      __typename: 'PinnedRoute',
      id: variables.path,
      path: variables.path,
      pinned: true,
      settings: {
        __typename: 'TravelPinnedSegmentUserSettings',
        id: 'tmp_1',
        notificationActive: true,
        notificationFrequency: 'DAILY',
        notificationChannel: 'EMAIL',
        pinned: true,
      },
    }),

    removeRoutePin: (
      variables: MutationRemoveRoutePinArgs,
    ): RemoveRoutePinMutation['removeRoutePin'] => {
      return variables.path
    },

    // updateTrip: (
    //   variables: UpdateTripMutationVariables,
    //   cache,
    // ): UpdateTripMutation['updateTrip'] => {
    //   if (variables?.id == null) return null
    //   const trip = cache.readFragment(TripDetailsFragmentDoc, { id: variables.id })
    //   const result: UpdateTripMutation['updateTrip'] = {
    //     ...(trip as UpdateTripMutation['updateTrip']),
    //   }

    //   const keysToUpdate: (keyof UpdateTripInput)[] = ['name', 'description', 'tags', 'clearTags']
    //   keysToUpdate.forEach((key) => {
    //     if (variables?.input?.[key] != null) result[key] = variables.input[key]
    //   })

    //   return result
    // },
  },

  updates: {
    Mutation: {
      removeRoutePin: removeRoutePinHandler,
      insertRoutePin: insertRoutePinHandler,
      insertHotelReservation: insertReservationHandler,
    },
  },
  resolvers: {
    Query: {
      hotelsConnection: relayPagination(),

      // hotel(parent, args, cache, info) {
      //   console.log('hotel', parent, args, cache, info)

      //   return { __typename: 'HotelProperty', id: args.id }
      // },

      // hotelLocal: (parent, args, cache, info) => {
      //   const { id } = args

      //   // console.log(args, cache.keyOfEntity({ __typename: 'HotelProperty', id: id as string }))

      //   const hotel = cache.readFragment(
      //     gql`
      //       fragment _ on HotelProperty {
      //         id
      //         name
      //         rates {
      //           totalCount
      //           edges {
      //             node {
      //               id
      //               minNightlyRate
      //             }
      //           }
      //         }
      //       }
      //     `,
      //     args,
      //   )
      //   return hotel
      // },
    },

    LodgingRoom: {
      // rates: relayPagination(),
    },

    HotelProperty: {
      // rates: relayPagination(),
      // photos: relayPagination({ mergeMode: 'inwards' }),
    },

    UserProfile: {
      dateOfBirth: dateTimeResolver,
    },
    ReservationItem: {
      checkIn: dateTimeResolver,
      checkOut: dateTimeResolver,
    },
    CancelPenalty: {
      start: dateTimeResolver,
      end: dateTimeResolver,
    },
    Contact: {
      createdAt: dateTimeResolver,
      updatedAt: dateTimeResolver,
    },
    RoomRate: {
      startDate: dateTimeResolver,
      endDate: dateTimeResolver,
    },
    Restriction: {
      lastCheckedAt: dateTimeResolver,
      stillCurrentAt: dateTimeResolver,
      updatedAt: dateTimeResolver,
      snapshotUpdatedAt: dateTimeResolver,
      startDate: dateTimeResolver,
      endDate: dateTimeResolver,
    },
    Reservation: {
      startsAt: dateTimeResolver,
      finishesAt: dateTimeResolver,
      createdAt: dateTimeResolver,
      updatedAt: dateTimeResolver,
      confirmedAt: dateTimeResolver,
      paidAt: dateTimeResolver,
      cancelledAt: dateTimeResolver,
      expiredAt: dateTimeResolver,
    },
    OfferFlight: {
      departingAt: dateTimeResolver,
      arrivingAt: dateTimeResolver,
    },

    // DateTime: (parent: DataFields, args: Variables, cache: Cache, info: ResolveInfo) => {
    //   console.log(parent, args, cache, info)
    //   return parent[info.fieldName]
    // },
    // Query: {
    //   node: (_, args) => {
    //     const typ = args.id.toString().slice(0, 3)
    //     if (typeNameMapping[typ]) {
    //       return {
    //         __typename: typeNameMapping[typ],
    //         id: args.id,
    //       }
    //     }

    //     return { id: args.id }
    //   },
    // },

    // Trip: {
    //   owner: (trip: Trip, args) => {
    //     return {
    //       __typename: 'User',
    //       id: trip.owner.id,
    //     }
    //   },
    // },
  },

  // optimistic: {
  //   insertTrip() {
  //     return {
  //       __typename: 'Trip',
  //       id: '1',
  //       name: 'New Trip',
  //       startDate: '2020-01-01',
  //       endDate: '2020-01-01',
  //       status: 'Current',
  //       segments: [],
  //     }
  //   },
  // },
}
