import type { AuthConfig } from '@urql/exchange-auth'
import { CombinedError, makeOperation, type Operation } from '@urql/svelte'
import { getIdTokenWithExpiresAt, signOut } from 'src/lib/authentication/helpers'
import { isAuthenticationError } from './error'

export type AuthState = {
  token: string
  expiresAt: number
  timezone: string
}

function isServer() {
  return typeof window === 'undefined'
}

// For an explanation of urql's auth flow, see
// https://formidable.com/open-source/urql/docs/advanced/authentication/
export const getAuth: AuthConfig<AuthState>['getAuth'] = async ({ authState }) => {
  if (isServer()) return null

  if (!authState) {
    const [token, expiresAt] = await getIdTokenWithExpiresAt()
    if (token) {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
      console.log(
        `Authentication token expires in ${Math.round((expiresAt - Date.now()) / 1000)} seconds`,
      )
      return { token, timezone, expiresAt }
    }
    return null
  }

  const [token, expiresAt] = await getIdTokenWithExpiresAt(true)
  if (token) {
    console.log('token refreshed')
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    return { token, timezone, expiresAt }
  }

  console.log('Signing out because token could not be refreshed')
  signOut()

  return null
}

export const addAuthToOperation: AuthConfig<AuthState>['addAuthToOperation'] = ({
  authState,
  operation,
}) => {
  if (!authState || !authState.token || authState.expiresAt < Date.now()) {
    return operation
  }

  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {}

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    fetchOptions: {
      ...fetchOptions,
      headers: {
        ...fetchOptions.headers,
        Authorization: `Bearer ${authState.token}`,
        'X-Timezone': authState.timezone,
      },
    },
  })
}

export const willAuthError = (params: { authState: AuthState | null; operation: Operation }) => {
  if (params.authState == null) return false

  // assumption: all operations used by the normalized client are user
  // specific and thus require a token for operation
  // If we ever want to query non-user specific operations, we'll need to
  // add the appropriate logic here
  if (!params?.authState?.token) {
    return true
  }

  if (params?.authState?.expiresAt != null) {
    if (params.authState.expiresAt < Date.now()) {
      return true
    }
  }

  return false
}

export const didAuthError = ({ error }: { error: CombinedError }) => {
  return isAuthenticationError(error)
}
