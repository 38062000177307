import { devtoolsExchange } from '@urql/devtools'
import { authExchange } from '@urql/exchange-auth'
import { cacheExchange as normalizedCacheExchange } from '@urql/exchange-graphcache'
import { refocusExchange } from '@urql/exchange-refocus'
import { retryExchange } from '@urql/exchange-retry'

import {
  CombinedError,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  type Operation,
} from '@urql/svelte'
import { normalizedCacheConfig } from './cache'
import { fetchOptions, isDev } from './client'
import { isAuthenticationError, isNetworkError } from './error'
import { buildUrl } from './url'

import { addAuthToOperation, didAuthError, getAuth, willAuthError } from './auth'
import { onError } from './error'

// TODO: if partial results are required, we should implement the schema awareness feature
// https://formidable.com/open-source/urql/docs/graphcache/schema-awareness/
// IMPORTANT: `normalizedClient` is only used for client-side rendering

export const makeNormalizedClient = () =>
  createClient({
    url: buildUrl(),
    requestPolicy: 'cache-first',
    exchanges: [
      isDev && devtoolsExchange,
      // IMPORTANT: all exchanges should be ordered synchronous first and asynchronous last.
      dedupExchange,
      normalizedCacheExchange(normalizedCacheConfig),

      refocusExchange(),
      retryExchange({
        maxNumberAttempts: 60,
        maxDelayMs: 5000,
        randomDelay: true,
        retryIf: (error: CombinedError, operation: Operation) => {
          if (isAuthenticationError(error)) {
            return true
          } else if (isNetworkError(error)) {
            return true
          }
        },
      }),
      // error exchange must come before fetch
      errorExchange({ onError }),

      authExchange({ getAuth, addAuthToOperation, didAuthError, willAuthError }),

      // import.meta.env.PROD &&
      //   persistedFetchExchange({
      //     // enforcePersistedQueries: true,
      //     preferGetForPersistedQueries: true,
      //   }),
      fetchExchange,
      // for best results, add the debugExchange between different exchanges as needed
      // debugExchange,
    ].filter(Boolean),
    fetchOptions: fetchOptions,
  })
// See https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/

export const normalizedClient = makeNormalizedClient()
