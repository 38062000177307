import type { InsertHotelReservationMutation } from '$lib/queries/generated/InsertHotelReservation'
import {
  HotelReservationsDocument,
  type HotelReservationsQuery,
  type HotelReservationsQueryVariables,
} from '$lib/queries/generated/QueryHotelReservation'
import type { Cache } from '@urql/exchange-graphcache'
import { cloneDeep } from 'lodash-es'
import type { MutationInsertHotelReservationArgs, ReservationEdge } from '../types'

export function insertReservationHandler(
  result: InsertHotelReservationMutation,
  args: MutationInsertHotelReservationArgs,
  cache: Cache,
) {
  // console.log(args)

  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'reservations')
    .forEach((field) => {
      cache.updateQuery<HotelReservationsQuery, HotelReservationsQueryVariables>(
        {
          query: HotelReservationsDocument,
          variables: {
            id: args.hotelID,
          },
        },
        (data) => {
          data = ensureConnection(data)

          const { edges } = data.reservations

          if (edges.find((e) => e.node.id === result.insertHotelReservation.id)) {
            return data
          }

          const newEdge = {
            cursor: null,
            node: result.insertHotelReservation,
            __typename: 'ReservationEdge',
          }
          const newEdges = edges.concat(newEdge as ReservationEdge)
          const newResult = {
            ...data,
            reservations: {
              ...data.reservations,
              edges: newEdges,
            },
          }

          return newResult
        },
      )
    })
}

function ensureConnection(data: HotelReservationsQuery | null): HotelReservationsQuery {
  const newData = data?.reservations
    ? cloneDeep(data)
    : ({ __typename: 'Query' } as HotelReservationsQuery)
  if (newData.reservations == null) {
    newData.reservations = {
      edges: [],
      __typename: 'ReservationConnection',
    }
  }
  return newData
}
