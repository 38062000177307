import { normalizedClient } from '$lib/graphql/makeNormalizedClient'
import { CurrentUserDocument, type CurrentUserQuery } from '$lib/queries/generated/currentUser'
import type { CurrentUser } from '$lib/stores/auth-state'
import feature from '$lib/stores/feature'
import modal from '$lib/stores/modal-state'
import state from '$stores/auth-state'
import type { User, UserCredential } from '@firebase/auth'
import { setMemberRatesCookie } from 'src/utils/member-rates'
import { pinOnLoadHandler } from 'src/utils/restrictions/pinning'
import { alias, identify } from 'src/utils/track'

function isCredential(thing: unknown): thing is UserCredential {
  return thing != null && (thing as UserCredential).user != null
}

// handleSignInResult handles auth change events, and updates the store with the current user
export const handleSignInResult = async (
  credential: UserCredential | User,
): Promise<CurrentUser> => {
  // credential = GoogleAuthProvider.credentialFromResult(credential as UserCredential)

  let user: User
  if (isCredential(credential)) {
    user = credential.user
  } else {
    user = credential
  }

  if (user == null) {
    throw new Error('User is null')
  }

  const currentUser = await state.loadCurrentUser(user)
  feature.setUser(currentUser)

  if (currentUser) {
    // Do this async so that we don't block the UI
    normalizedClient
      .query<CurrentUserQuery>(CurrentUserDocument, {})
      .toPromise()
      .then(({ data, error }) => {
        if (error == null && data?.currentUser) setupIntercomInfo(data.currentUser)
        if (data?.currentUser == null) return

        identify(data.currentUser.id, {
          email: data.currentUser.email,
          fullName: data.currentUser.fullName ?? '',
        })
        const userId = user.uid
        alias(data.currentUser.id, userId)
      })

    await pinOnLoadHandler()
    setMemberRatesCookie(true)
    await state.resolveAuthCallbacks(null)
    modal.close()
    console.info(`[AUTH] Signed in`)
    return currentUser
  } else {
    state.clearCurrentUser()
    setupIntercomInfo(null)
    console.error(`[AUTH] Failed to sign in`)
    await state.resolveAuthCallbacks(new Error('Authentication failed'))
    return Promise.reject()
  }
}

export function setupIntercomInfo(currentUser?: CurrentUserQuery['currentUser']) {
  console.log('[AUTH] Setting up intercom info')

  if (currentUser) {
    window.intercomSettings = {
      ...currentUser.intercomSettings,
      hide_default_launcher: true,
    }
  } else {
    window.intercomSettings = {
      hide_default_launcher: true,
    }
  }
}
