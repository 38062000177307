import { goto } from '$app/navigation'
import { normalizedClient } from '$lib/graphql/makeNormalizedClient'
import type { CountryDataFragment } from '$lib/queries/fragments/generated/CountryData'
import {
  InsertRoutePinDocument,
  type InsertRoutePinMutation,
  type InsertRoutePinMutationVariables,
} from '$lib/queries/generated/InsertRoutePin'
import type { PinnedRoutesQuery } from '$lib/queries/generated/QueryPinnedRoutes'
import { RoutePath } from './routepath'

export const pinnedIDs = (data: PinnedRoutesQuery, originId: string): string[] => {
  const edges = data?.pinnedRoutesConnection?.edges ?? []

  return (
    // note: return null otherwise to prevent incorrect false value from getting stored on the row
    edges
      ?.filter((e) => e?.node?.pinned == true)
      ?.map((e) => RoutePath.parse(e.node.path))
      ?.filter((path) => path.originId == originId)
      ?.map((path) => path.destinationId) ?? []
  )
}

export type ErrorSetter = Dispatch<string>

export const clearPinOnLoadParams = (): Promise<unknown> => {
  const url = new URL(window.location.href)
  const query = url.searchParams

  if (query.has('pinOnLoad')) {
    query.delete('pinOnLoad')
    query.delete('pinOrigin')
    query.delete('pinDestination')
    url.search = query.toString()
    // console.log(`[AUTH] Clearing pinOnLoad params: ${url.href}`)

    return goto(url.toString())
  }
}

export const getShouldPinOnLoad = () => new URLSearchParams(window.location.search).has('pinOnLoad')

export const loginAndPin = (
  signInAsync: () => Promise<void>,
  pinOperation: () => void,
  setErrorString?: ErrorSetter,
  origin?: CountryDataFragment,
  destination?: CountryDataFragment,
) => {
  setPinCallbackState(origin, destination)
  return signInAsync()
    .then(pinOperation)
    .catch(() => {
      if (setErrorString) {
        setErrorString('Error logging in. Please try again.')
      }
    })
}

export function setPinCallbackState(origin: CountryDataFragment, destination: CountryDataFragment) {
  const url = new URL(window.location.href)
  const urlParams = url.searchParams

  urlParams.set('pinOnLoad', 'true')
  if (origin && destination) {
    urlParams.set('pinOrigin', origin.id)
    urlParams.set('pinDestination', destination.id)
  } else if (origin || destination) {
    return
  }

  url.search = urlParams.toString()
  return goto(url.toString())
}

export async function pinOnLoadHandler() {
  if (!getShouldPinOnLoad()) return

  const client = normalizedClient
  const params = new URLSearchParams(window.location.search)

  const origin = params.get('pinOrigin')
  const destination = params.get('pinDestination')
  const routePath = `${origin}/${destination}`

  return client
    .mutation<InsertRoutePinMutation, InsertRoutePinMutationVariables>(InsertRoutePinDocument, {
      path: routePath,
    })
    .toPromise()
    .then(() => {
      clearPinOnLoadParams()
    })
}
