import type {
  InsertRoutePinMutation,
  InsertRoutePinMutationVariables,
} from '$lib/queries/generated/InsertRoutePin'
import {
  PinnedRoutesDocument,
  type PinnedRoutesQuery,
  type PinnedRoutesQueryVariables,
} from '$lib/queries/generated/QueryPinnedRoutes'
import type {
  RemoveRoutePinMutation,
  RemoveRoutePinMutationVariables,
} from '$lib/queries/generated/RemoveRoutePin'
import type { Cache } from '@urql/exchange-graphcache'
import { cloneDeep } from 'lodash-es'
import type { PinnedRouteEdge, TravelPinnedSegmentConnectionFilterInput } from '../types.d'

export function insertRoutePinHandler(
  result: InsertRoutePinMutation,
  args: InsertRoutePinMutationVariables,
  cache: Cache,
) {
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'pinnedRoutesConnection')
    .forEach((field) => {
      cache.updateQuery<PinnedRoutesQuery, PinnedRoutesQueryVariables>(
        {
          query: PinnedRoutesDocument,
          variables: {
            filter: field?.arguments?.filter as TravelPinnedSegmentConnectionFilterInput,
          },
        },
        (data) => {
          data = ensurePinnedRoutesConnection(data)
          const { edges, pageInfo } = data.pinnedRoutesConnection
          const newEdge = {
            cursor: null,
            node: result.insertRoutePin,
            __typename: 'PinnedRouteEdge',
          }
          const newEdges = edges.concat(newEdge as PinnedRouteEdge)
          const newPageInfo = {
            ...pageInfo,
            hasNextPage: false,
          }
          return {
            ...data,
            pinnedRoutesConnection: {
              ...data.pinnedRoutesConnection,
              edges: newEdges,
              pageInfo: newPageInfo,
            },
          }
        },
      )
    })
}

export function removeRoutePinHandler(
  result: RemoveRoutePinMutation,
  args: RemoveRoutePinMutationVariables,
  cache: Cache,
) {
  const path = args.path
  cache
    .inspectFields('Query')
    .filter((field) => field.fieldName === 'pinnedRoutesConnection')
    .forEach((field) => {
      cache.updateQuery<PinnedRoutesQuery, PinnedRoutesQueryVariables>(
        {
          query: PinnedRoutesDocument,
          variables: {
            filter: field?.arguments?.filter as TravelPinnedSegmentConnectionFilterInput,
          },
        },
        (data) => {
          data = ensurePinnedRoutesConnection(data)
          // data.todos = data.todos.filter((todo) => todo.id !== args.id)
          data.pinnedRoutesConnection.edges = data.pinnedRoutesConnection.edges.filter(
            (e) => e.node.path != path,
          )
          return data
        },
      )
    })
}

function ensurePinnedRoutesConnection(data: PinnedRoutesQuery | null): PinnedRoutesQuery {
  const newData = data ? cloneDeep(data) : ({ __typename: 'Query' } as PinnedRoutesQuery)
  if (newData.pinnedRoutesConnection == null) {
    newData.pinnedRoutesConnection = {
      edges: [],
      totalCount: 0,
      pageInfo: null,
      __typename: 'PinnedRouteConnection',
    }
  }
  return newData
}
